/**
 * SEO component that queries for datas with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function Seo({ description, lang, meta, title, image }) {
  var { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  var currentUrl = ""
  currentUrl = process.env.GATSBY_SITE_URL + pathname

  // set no index for result pagination
  let hasPagination = false
  if (pathname !== undefined && pathname) {
    let pageStr = pathname.split("page-")
    hasPagination = pageStr[1] || false
    if (pageStr[0] !== undefined) {
      currentUrl = process.env.GATSBY_SITE_URL + pageStr[0]
    }
  }

  if (!currentUrl.endsWith("/")) currentUrl = currentUrl + "/"

  //remove multiple slashes
  currentUrl = currentUrl.replace(/([^:]\/)\/+/g, "$1")
  //set lowercase url
  currentUrl = currentUrl.toLowerCase()

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          href: currentUrl,
          rel: "canonical",
        },
      ]}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `fragment`,
          content: `!`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:image:secure_url`,
          content: image,
        },
        {
          property: `og:image:type`,
          content: `image/jpeg`,
        },
        {
          property: `og:image:width`,
          content: `400`,
        },
        {
          property: `og:image:height`,
          content: `300`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: currentUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
